import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { personalizeProfile } from "../components/APIs/Api";
import PageTitle from "../layouts/PageTitle";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import AWS from "aws-sdk";

export default function PersonalizeProfile(props) {
  const userId = localStorage.getItem("VendorId");
  const s3 = new AWS.S3();

  let errorsObj = { title: "", body: "", type: "", selected: [] };
  const [errors, setErrors] = useState(errorsObj);
  const [about, setAbout] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [selectCoverImage, setSelectedCoverImage] = useState("");
  const [selectAdditionalImage, setSelectedAdditionalImage] = useState("");

  const [coverImage, setCoverImage] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleUploadAdditional = async () => {
    if (!additionalImages || additionalImages.length === 0) {
      console.error("No files selected");
      return [];
    }

    const uploadPromises = additionalImages.map(async (imageObj, index) => {
      const file = new File([imageObj.image], new Date().getTime());
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `${file.name}_${index}`,
        Body: file,
      };

      try {
        const data = await s3.upload(params).promise();
        return { imageName: data.Key };
      } catch (error) {
        console.error(
          `Upload failed for additional image ${index + 1}:`,
          error
        );
        return null;
      }
    });

    const uploadedImages = await Promise.all(uploadPromises);

    const validImages = uploadedImages.filter((image) => image !== null);
    setSelectedAdditionalImage(validImages);

    return validImages;
  };

  const handleAdditionalImagesChange = async (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const newAdditionalImages = files.map((file) => ({ image: file }));
      setAdditionalImages(newAdditionalImages);
    }
  };

  const handleUpload = async () => {
    if (!coverImage) {
      console.error("No file selected");
      return null;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: coverImage?.name,
      Body: coverImage,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoader(true);

    try {
      const additionalData = await handleUploadAdditional();
      const imageData = await handleUpload();

      if (!imageData || additionalData.length === 0) {
        throw new Error("File upload failed");
      }

      const res = await personalizeProfile(
        about,
        websiteUrl,
        imageData?.Key,
        additionalData.map((image) => ({ image: image.imageName })),
        userId
      );

      toast.success(`Add SuccessFul`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
      props.history.push("/vendor-management");
      localStorage.removeItem("VendorId");
    } catch (error) {
      console.log(error, "error");
      toast.error(`❌${error?.response?.data?.data}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Personalize Profile" motherMenu="Vendor" />
      <div className="col-lg-12 mb-5">
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <div className="authincation-content text-black p-5">
            <div className="mb-4">
              <h3 className="mb-1 font-w600 text-black ">
                Personalize Profile
              </h3>
            </div>
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="mb-2 ">About</label>
                <textarea
                  type="text"
                  required
                  className="form-control"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  placeholder="Type here.."
                />
                {errors.title && (
                  <div className="text-danger fs-12">{errors.title}</div>
                )}
              </div>
              <div className="form-group">
                <label className="text-black font-w500">Website URL</label>
                <input
                  type="url"
                  required
                  className="form-control"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  placeholder="Enter link here"
                />

                {errors.type && (
                  <div className="text-danger fs-12">{errors.type}</div>
                )}
              </div>
              <div className="form-group mt-3">
                <label className="text-black font-w500">Cover Image</label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="form-control"
                  required
                  onChange={(e) => setCoverImage(e.target.files[0])}
                  placeholder="Type here..."
                />
              </div>
              <div className="form-group mt-3">
                <label className="text-black font-w500">
                  Additional Images
                </label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  multiple
                  className="form-control"
                  onChange={(e) => handleAdditionalImagesChange(e)}
                />
              </div>
              <div className="text-right ">
                <button
                  type="submit"
                  className="btn text-white   mr-3"
                  style={{ backgroundColor: "#F36C03", border: "none" }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
